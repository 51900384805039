<template>
    <div class="cert">
        <div class="title">多项认证，安全保障</div>
        <div class="sub-title">专业、安全、可靠，多重安全合规资质</div>
        <div class="list">
            <div v-for="(item, index) in list" :key="index" class="label">
                <el-image :src="item.image" fit="contain" />
                <div class="desc">{{ item.desc }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import cert1 from '../../assets/cert1.png'
import cert2 from '../../assets/cert2.png'
import cert3 from '../../assets/cert3.png'

export default {
    data() {
        return {
            list: [{
                id: 1,
                image: cert1,
                desc: '5A级信用企业'
            }, {
                id: 2,
                image: cert3,
                desc: '民营科技企业'
            }, {
                id: 3,
                image: cert2,
                desc: '江苏省高新技术企业'
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
.cert {
    height: 400px;
    padding-top: 100px;

    .title {
        font-size: 28px;
        line-height: 48px;
    }

    .sub-title {
        color: #737373;
        font-size: 16px;
        line-height: 48px;
    }

    .list {
        display: flex;
        justify-content: center;
        align-items: center;

        .label {
            width: 250px;
            margin-top: 50px;

            .el-image {
                width: 100px;
                height: 100px;
            }

            .desc {
                color: #737373;
                font-size: 14px;
                line-height: 48px;
            }
        }
    }
}
</style>
