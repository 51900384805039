<template>
    <div class="top">
        <div class="logo">
            <el-image :src="logo" fit="contain" />
        </div>
        <div class="menu">
            <div class="menu-left">
                <el-menu :default-active="activeIndex" :router="true" :ellipsis="false" text-color="#333333" active-text-color="#3A80F4" mode="horizontal">
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-sub-menu index="server" popper-class="customize-sub-menu">
                        <template #title>产品及服务</template>
                        <el-menu-item v-for="(item, index) in list" :key="index" :index="item.path">
                            <el-image :src="item.icon" fit="contain" />
                            <div class="right">
                                <div class="name">{{ item.name }}</div>
                                <div class="desc">{{ item.desc }}</div>
                            </div>
                        </el-menu-item>
                    </el-sub-menu>
                    <el-menu-item index="channel">渠道合作</el-menu-item>
                    <el-menu-item index="industry">行业资讯</el-menu-item>
                    <el-menu-item index="about">关于我们</el-menu-item>
                    <el-menu-item index="update">更新记录</el-menu-item>
                    <el-menu-item>
                        <a href="https://docs.xxpiggy.com/web/#/5/561" class="document" target="_blank">技术文档</a>
                    </el-menu-item>
                </el-menu>
                <el-icon class="mg-left30" :size="16" color="#4285F4">
                    <phone-filled />
                </el-icon>
                <div class="phone">{{ website.tel }}</div>
            </div>
            <div class="menu-right">
                <div class="login">登录</div>
                <div class="register">免费注册</div>
            </div>
        </div>
    </div>
</template>

<script>
import xxz_logo from '../../assets/xxz_logo.png'
import mall from '../../assets/nav/mall.png'
import shop from '../../assets/nav/shop.png'
import food from '../../assets/nav/food.png'
import meeting from '../../assets/nav/meeting.png'
import tuoke from '../../assets/nav/tuoke.png'
import cashier from '../../assets/nav/cashier.png'
import factory from '../../assets/nav/factory.png'
import token from '../../assets/nav/token.png'
import steel from '../../assets/nav/steel.png'

export default {
    props: {
        website: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            logo: xxz_logo,
            activeIndex: '/',
            list: [{
                id: 1,
                icon: mall,
                path: 'server?type=mall',
                name: '小玄猪商城',
                desc: '专业开发，多端适配'
            }, {
                id: 3,
                icon: shop,
                path: 'server?type=shop',
                name: '小玄猪导购',
                desc: '数字化平台赋能导购'
            }, {
                id: 4,
                icon: food,
                path: 'server?type=food',
                name: '小玄猪餐饮',
                desc: '快速做活动，攒人气拉流量'
            }, {
                id: 5,
                icon: meeting,
                path: 'server?type=meeting',
                name: '小玄猪会议活动',
                desc: '专业人员供应，提供各种方案'
            }, {
                id: 6,
                icon: tuoke,
                path: 'server?type=tuoke',
                name: '小玄猪拓客',
                desc: '低成本高效精准拓客'
            }, {
                id: 7,
                icon: cashier,
                path: 'server?type=cashier',
                name: '小玄猪收银',
                desc: '高效系统管理，降本增效'
            }, {
                id: 8,
                icon: factory,
                path: 'server?type=factory',
                name: '小玄猪数字工厂',
                desc: '一站式数智工厂解决方案'
            }, {
                id: 9,
                icon: token,
                path: 'server?type=token',
                name: '小玄猪通证激励',
                desc: '量身定制，专业一体化'
            }, {
                id: 10,
                icon: steel,
                path: 'server?type=steel',
                name: '小玄猪钢贸ERP',
                desc: '全场景全链路的钢贸SAAS场景'
            }]
        }
    },
    created() {
        let name = this.$route.name
        switch (name) {
            case 'home':
                this.activeIndex = '/'
                break;
            case 'caseDetail':
                this.activeIndex = 'case'
                break;
            case 'industryDetail':
                this.activeIndex = 'industry'
                break;
            default:
                if (name === 'server') {
                    let type = this.$route.query.type
                    this.activeIndex = name + '?type=' + type
                } else {
                    this.activeIndex = name
                }
                break;
        }
    }
}
</script>

<style lang="scss" scoped>
.top {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        color: #4184F4;
        font-size: 14px;

        .el-image {
            width: 117px;
            height: 36px;
        }
    }

    .menu {
        width: 1020px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .el-menu--horizontal {
                border-bottom: none;
            }

            .el-menu {
                --el-menu-hover-bg-color: #fff;
                --el-menu-item-hover-fill: #fff;
                --el-menu-item-height: 60px;
            }

            .el-menu-item {
                color: #4D4D4D;
                font-size: 14px;
                padding: 0 0;
                margin: 0 15px;
            }

            .el-menu--horizontal>.el-menu-item {
                border-bottom: none;
            }

            .el-menu--horizontal>.el-menu-item.is-active {
                border-bottom: none;
            }

            .mg-left30 {
                margin-left: 30px;
            }

            .document {
                color: #4D4D4D;
                text-decoration: none;
            }

            .phone {
                color: #4D4D4D;
                font-size: 14px;
                font-weight: bold;
                margin-left: 6px;
            }
        }

        .menu-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .login {
                color: #4D4D4D;
                font-size: 14px;
                cursor: pointer;
            }

            .register {
                width: 92px;
                height: 40px;
                background: #4285F4;
                border-radius: 2px;
                text-align: center;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 40px;
                cursor: pointer;
                margin-left: 24px;
            }
        }
    }
}
</style>

<style>
.menu .el-sub-menu__title {
    color: #4D4D4D;
    font-size: 14px;
    padding: 0 0;
    margin: 0 15px;
}

.menu .el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
    border-bottom: none;
}

.menu .el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
    border-bottom: none;
}

.el-popper.customize-sub-menu {
    left: 0 !important;
    right: 0 !important;
    top: 60px !important;
    background: none !important;
    border: none !important;
}

.el-popper.customize-sub-menu .el-menu--popup {
    width: 730px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    padding: 10px 20px;
}

.el-popper.customize-sub-menu .el-menu--popup .el-menu-item {
    width: 230px;
    height: 40px !important;
    line-height: 20px;
    --el-menu-hover-bg-color: #fff;
    --el-menu-item-hover-fill: #fff;
}

.el-popper.customize-sub-menu .el-menu--popup .el-menu-item .el-image {
    width: 40px;
    height: 40px;
}

.el-popper.customize-sub-menu .el-menu--popup .el-menu-item .right {
    margin-left: 10px;
}

.el-popper.customize-sub-menu .el-menu--popup .el-menu-item .right .name {
    color: #333333;
    font-size: 14px;
}

.el-popper.customize-sub-menu .el-menu--popup .el-menu-item .right .desc {
    color: #737373;
    font-size: 12px;
}
</style>
