<template>
    <div class="advantage">
        <div class="title">公司实力与服务优势</div>
        <div class="sub-title">7+年技术沉淀，服务于各行各业，为商家提供稳定的服务</div>
        <div class="show-list">
            <div class="box-item">
                <div class="item-num">
                    <span class="num">1VS1专家服务</span>
                </div>
                <div class="item-desc">专业技术团队</div>
            </div>
            <div class="line" />
            <div class="box-item">
                <div class="item-num">
                    <span class="num">7</span>
                    <span class="word">年</span>
                    <span class="plus">+</span>
                </div>
                <div class="item-desc">技术沉淀</div>
            </div>
            <div class="line" />
            <div class="box-item">
                <div class="item-num">
                    <span class="num">7*12</span>
                    <span class="plus">h</span>
                </div>
                <div class="item-desc">快速响应服务</div>
            </div>
        </div>
        <div class="advantage-list">
            <div class="advantage-box adv-bg1">
                <div class="advantage-title">
                    <div class="advantage-title-one">自主软件版权</div>
                    <div class="advantage-title-two">使用更安心</div>
                </div>
                <div class="advantage-desc">
                    <div>7+年的电商行业经验</div>
                    <div>一站式企业服务提供</div>
                    <div>30+项专利及著作权</div>
                    <div>商城系统运营更安心、更省心</div>
                </div>
            </div>
            <div class="advantage-box adv-bg2">
                <div class="advantage-title">
                    <div class="advantage-title-one">专业售后保障</div>
                    <div class="advantage-title-two">只为客户成功</div>
                </div>
                <div class="advantage-desc">
                    <div>提供7*12小时技术运维支持</div>
                    <div>一对一售后群服务</div>
                    <div>定期系统操作远程在线培训</div>
                    <div>售后服务期内免费升级等</div>
                </div>
            </div>
            <div class="advantage-box adv-bg3">
                <div class="advantage-title">
                    <div class="advantage-title-one">多重安全保障</div>
                    <div class="advantage-title-two">数据私密安全</div>
                </div>
                <div class="advantage-desc">
                    <div>掌握一手数据资源，安全可控</div>
                    <div>防止大数据抓取避免私密数据外泄</div>
                    <div>利用客户自身的服务器部署</div>
                    <div>不必担心会员/订单数据丢失</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import advantage from '../../assets/advantage.png'

export default {
    data() {
        return {
            advantage: advantage
        }
    }
}
</script>

<style lang="scss" scoped>
.advantage {
    height: 720px;
    padding-top: 100px;
    background: linear-gradient(#FFFFFF, #F6F9FC);

    .title {
        font-size: 28px;
        line-height: 48px;
    }

    .sub-title {
        color: #737373;
        font-size: 16px;
        line-height: 48px;
    }

    .show-list {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;

        .box-item {
            width: 250px;

            .item-num {
                font-weight: bold;
                color: #4285F4;
                margin-bottom: 20px;

                .num {
                    font-size: 30px;
                }

                .word {
                    font-size: 18px;
                }

                .plus {
                    font-size: 24px;
                }
            }

            .item-desc {
                font-size: 16px;
                color: #adadad;
            }
        }

        .line {
            width: 1px;
            height: 62px;
            background-color: #DCDCDC;
            margin: 0 20px;
        }
    }

    .advantage-list {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .advantage-box {
            width: 456px;
            height: 403px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            .advantage-title {
                padding: 60px 0 0 65px;
                text-align: left;

                .advantage-title-one {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 65px;
                }

                .advantage-title-two {
                    font-size: 19px;
                    color: #929191;
                    margin-top: -5px;
                }
            }

            .advantage-desc {
                padding: 45px 0 0 100px;
                font-size: 19px;
                color: #575757;
                text-align: left;
                line-height: 38px;
            }
        }

        .adv-bg1 {
            background-image: url("../../assets/ad1.png");
            margin-right: -50px;
        }

        .adv-bg2 {
            background-image: url("../../assets/ad2.png");
        }

        .adv-bg3 {
            background-image: url("../../assets/ad3.png");
            margin-left: -50px;
        }
    }
}
</style>
