import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'
import BaiduMap from 'vue-baidu-map-3x'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MetaInfo from 'vue-meta-info'

const app = createApp(App)

app.use(store).use(ElementPlus).use(router).use(MetaInfo).use(BaiduMap, { ak: 'EfWxyX3xIt4v1fBdMvR53NxOBbi6BZuY' }).mount('#app')

// 注册全局组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons])
})
