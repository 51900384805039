<template>
    <el-dialog v-model="dialogVisible" title="微信咨询" width="320px" @close="closeDialogVisible">
        <el-image :src="qrcode" class="qrcode" fit="contain" />
        <div class="desc">手机扫码加我微信</div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        qrcode: {
            type: String,
            require: true
        },
        show: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            dialogVisible: false
        }
    },
    created() {
        this.dialogVisible = this.show
    },
    methods: {
        // 关闭弹窗
        closeDialogVisible() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.qrcode {
    width: 196px;
    height: 196px;
}

.desc {
    font-size: 18px;
    margin-top: 20px;
}
</style>
