import service from '../utils/request'

// 配置
export function website(data) {
    return service.request({
        method: 'get',
        url: '/plugin.website.website/website',
        params: data
    })
}

// 最新资讯
export function latestNews() {
    return service.request({
        method: 'get',
        url: '/plugin.website.website/getNews'
    })
}

// 分类列表
export function categoryList() {
    return service.request({
        method: 'get',
        url: '/plugin.website.website/category'
    })
}

// 文章列表
export function articleList(data) {
    return service.request({
        method: 'post',
        url: '/plugin.website.website/index',
        params: data
    })
}

// 文章详情
export function articleDetail(data) {
    return service.request({
        method: 'get',
        url: '/plugin.website.website/detail',
        params: data
    })
}

// 预约回电
export function reservationCall(data) {
    return service.request({
        method: 'post',
        url: '/plugin.website.website/reservation',
        params: data
    })
}

// 预约演示
export function reservationDemo(data) {
    return service.request({
        method: 'post',
        url: '/plugin.website.website/demonstrate',
        params: data
    })
}

// 更新记录
export function logList(data) {
    return service.request({
        method: 'post',
        url: '/plugin.website.website/log',
        params: data
    })
}

// 下载
export function downloadUrl(data) {
    return service.request({
        method: 'post',
        url: '/plugin.website.website/download',
        params: data
    })
}
