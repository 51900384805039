<template>
    <div class="digital">
        <div class="title">小玄猪数字化服务</div>
        <div class="sub-title">打造全渠道数字经营新蓝海，为企业数字化升级提供一站式服务</div>
        <el-image :src="server" fit="contain" />
    </div>
</template>

<script>
import server1 from '../../assets/server2.png'

export default {
    data() {
        return {
            server: server1
        }
    }
}
</script>

<style lang="scss" scoped>
.digital {
    height: 600px;
    padding-top: 100px;
    background: linear-gradient(#FFFFFF, #F6F9FC);

    .title {
        font-size: 28px;
        line-height: 48px;
    }

    .sub-title {
        color: #737373;
        font-size: 16px;
        line-height: 48px;
    }

    .el-image {
        width: 1060px;
        height: 331px;
        margin-top: 50px;
    }
}
</style>
