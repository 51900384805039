<template>
    <div class="banner">
        <el-carousel :height="height + 'px'" indicator-position="none">
            <el-carousel-item v-for="(item, index) in list" :key="index">
                <el-image ref="height" :src="item.banner" fit="contain" />
                <div class="carousel-box">
                    <div class="c-title">
                        <span v-if="item.name !== ''" class="c-prev">{{ item.name }}</span>
                        <span class="c-next">{{ item.name2 }}</span>
                    </div>
                    <div class="c-sub-title">{{ item.sub_name }}</div>
                    <div class="c-third-title">{{ item.desc }}</div>
                    <div class="c-button-style">
                        <el-button type="primary" class="c-button1">{{ item.button_text }}</el-button>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="fix">
            <div
                class="info-bg"
                :style="{
                    background: 'url(' + bannerBg + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }"
            >
                <div class="info">
                    <div class="first">
                        <el-image :src="icon1" fit="contain" />
                        <div class="text">一站式服务</div>
                    </div>
                    <div class="desc">打造全渠道数字经营新蓝海，为企业数字化升级提供一站式服务。</div>
                </div>
            </div>

            <div
                class="info-bg"
                :style="{
                    background: 'url(' + bannerBg + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }"
            >
                <div class="info">
                    <div class="first">
                        <el-image :src="icon2" fit="contain" />
                        <div class="text">100+应用场景</div>
                    </div>
                    <div class="desc">丰富的行业经验，100+应用场景；只为提供好服务，一起成长。</div>
                </div>
            </div>

            <div
                class="info-bg"
                :style="{
                    background: 'url(' + bannerBg + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }"
            >
                <div class="info">
                    <div class="first">
                        <el-image :src="icon3" fit="contain" />
                        <div class="text">领先技术平台</div>
                    </div>
                    <div class="desc">7年技术沉淀，做到区块链级安全可靠，企业经营数据有保障。</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../../assets/banner-1.png'
import banner1 from '../../assets/banner1-1.png'
import bannerBg from '../../assets/banner-bg.png'
import icon1 from '../../assets/icon1.png'
import icon2 from '../../assets/icon2.png'
import icon3 from '../../assets/icon3.png'

export default {
    data() {
        return {
            list: [{
                name: '小玄猪',
                name2: '企业数字化',
                banner: banner,
                sub_name: '创新升级服务商',
                desc: '发挥数据价值，驱动业务增长',
                button_text: '了解更多',
                link: ''
            }, {
                name: '',
                name2: '企业数字化转型',
                banner: banner1,
                sub_name: '助力企业 成就愿景',
                desc: '提供数字化服务，助力组织数字化转型，实现客户体验升级、企业业绩增长与持续发展以及企业业务创新。',
                button_text: '了解更多',
                link: ''
            }],
            height: '604',
            bannerBg: bannerBg,
            icon1: icon1,
            icon2: icon2,
            icon3: icon3
        }
    },
    created() {
        window.addEventListener('resize', this.getHeight)
        this.getHeight()
    },
    unmounted() {
        window.removeEventListener('resize', this.getHeight)
    },
    methods: {
        // 获取高度
        getHeight() {
            this.height = 604 / 1920 * window.innerWidth
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    position: relative;

    .carousel-box {
        width: 1200px;
        position: absolute;
        left: 50%;
        top: 3vw;
        margin-left: -600px;
        text-align: left;

        .c-title {
            font-size: 2vw;
            font-weight: bold;

            .c-prev {
                color: #4184F4;
                margin-right: 20px;
            }

            .c-next {
                color: #333333;
            }
        }

        .c-sub-title {
            font-size: 2vw;
            padding-top: 0.3vw;
        }

        .c-third-title {
            width: 30vw;
            color: #666666;
            font-size: 1vw;
            line-height: 24px;
            padding-top: 1vw;
        }

        .c-button-style {
            margin-top: 2vw;

            .c-button1 {
                width: 120px;
                height: 44px;
                border: 1px solid #4285F4;
                border-radius: 2px;
                font-size: 16px;
                font-weight: normal;
                color: #FFFFFF;
            }
        }
    }

    .fix {
        width: 1200px;
        height: 200px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -600px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info-bg {
            width: 363px;
            height: 156px;

            .info {
                width: 341px;
                height: 134px;
                text-align: left;

                .first {
                    display: flex;
                    align-items: center;
                    padding: 20px;

                    .el-image {
                        width: 28px;
                        height: 28px;
                    }

                    .text {
                        color: #404040;
                        font-size: 18px;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                .desc {
                    color: #737373;
                    font-size: 14px;
                    line-height: 22px;
                    padding: 0 20px 20px;
                }
            }
        }
    }
}
</style>
