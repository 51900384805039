<template>
    <div class="news">
        <el-card class="box-card">
            <div
                class="card-header"
                :style="{
                    background: 'url(' + news1 + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }"
            >
                <div class="header-left">
                    <div class="en-title">COMPANY DYNAMIC</div>
                    <div class="title-flex">
                        <div class="title">公司动态</div>
                        <div class="line" />
                        <div class="desc">数字化升级服务商</div>
                    </div>
                </div>
                <div class="header-right">
                    <span class="more">更多</span>
                    <el-icon class="white" :size="12">
                        <arrow-right />
                    </el-icon>
                </div>
            </div>
            <div v-for="(item, index) in list" :key="index" class="card-content">
                <div class="date">{{ item.create_time.substring(0, 10) }}</div>
                <div class="text">{{ item.article_title }}</div>
            </div>
        </el-card>

        <el-card class="box-card">
            <div
                class="card-header"
                :style="{
                    background: 'url(' + news2 + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }"
            >
                <div class="header-left">
                    <div class="en-title">XIAOXUANZHU NEWS</div>
                    <div class="title-flex">
                        <div class="title">干货资讯</div>
                        <div class="line" />
                        <div class="desc">行业前线</div>
                    </div>
                </div>
                <div class="header-right">
                    <span class="more">更多</span>
                    <el-icon class="white" :size="12">
                        <arrow-right />
                    </el-icon>
                </div>
            </div>
            <div v-for="(item, index) in list" :key="index" class="card-content">
                <div class="date">{{ item.create_time.substring(0, 10) }}</div>
                <div class="text">{{ item.article_title }}</div>
            </div>
        </el-card>
    </div>
</template>

<script>
import news1 from '../../assets/news1-1.png'
import news2 from '../../assets/news2-1.png'
import { latestNews } from '@/api/site'

export default {
    data() {
        return {
            news1: news1,
            news2: news2,
            list: []
        }
    },
    created() {
        this.getLatestNews()
    },
    methods: {
        // 获取最新资讯
        getLatestNews() {
            latestNews().then((res) => {
                this.list = res.data.data.list
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.news {
    height: 580px;
    background: linear-gradient(#F6F9FC, #EAF1FD);
    display: flex;
    justify-content: center;
    align-items: center;

    .box-card {
        width: 590px;
        height: 390px;
        margin: 0 10px;

        .card-header {
            width: 590px;
            height: 169px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: none;

            .header-left {
                padding-left: 30px;
                text-align: left;

                .en-title {
                    color: #FFFFFF;
                    opacity: 0.6;
                    font-size: 12px;
                    line-height: 48px;
                }

                .title-flex {
                    display: flex;
                    align-items: center;

                    .title {
                        color: #FFFFFF;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 24px;
                    }

                    .line {
                        width: 1px;
                        height: 20px;
                        background-color: rgba(255, 255, 255, 0.6);
                        margin: 0 10px;
                    }

                    .desc {
                        color: #FFFFFF;
                        opacity: 0.6;
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }

            .header-right {
                display: flex;
                align-items: center;
                padding-right: 30px;

                .more {
                    color: #FFFFFF;
                    opacity: 0.6;
                    font-size: 14px;
                    cursor: pointer;
                }

                .white {
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }

        .card-content {
            display: flex;
            align-items: center;
            padding: 20px 30px 0;

            .date {
                color: #999999;
                font-size: 14px;
                line-height: 24px;
            }

            .text {
                width: 438px;
                text-align: left;
                font-size: 14px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 10px;
            }
        }
    }

    .el-card {
        --el-card-padding: 0;
    }
}
</style>
