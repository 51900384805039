<template>
    <div>
        <div
            v-if="!!show"
            class="bottom"
            :style="{
                background: 'url(' + bottom + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }"
        >
            <div class="desc">{{ text }}</div>
            <el-button class="button" @click="openKefu">
                {{ button }}<el-icon class="mg-left7" :size="13"><arrow-right /></el-icon>
            </el-button>
        </div>

        <div class="bottom-bg">
            <div class="concat">
                <div class="phone">
                    <div class="consult">
                        <el-image :src="headset" />
                        <div class="title">咨询热线</div>
                    </div>
                    <div class="mobile">{{ website.tel }}</div>
                    <div class="text">地址：{{ website.address }}</div>
                    <div class="text">邮编：{{ website.post_code }}</div>
                </div>
                <div class="vertical" />
                <div class="box">
                    <div class="title">产品服务</div>
                    <div class="nav" @click="directUp('server?type=mall')">小玄猪商城</div>
                    <div class="nav" @click="directUp('server?type=shop')">小玄猪导购</div>
                    <div class="nav" @click="directUp('server?type=cashier')">小玄猪收银</div>
                    <div class="nav" @click="directUp('server?type=tuoke')">小玄猪拓客</div>
                </div>
                <div class="box">
                    <div class="title">合作伙伴</div>
                    <div class="nav">申请加入</div>
                    <div class="nav">战略合作伙伴</div>
                    <div class="nav">个人推介</div>
                </div>
                <div class="box">
                    <div class="title">客户案例</div>
                    <div class="nav">上海债悟通</div>
                    <div class="nav">金福祥钢材</div>
                    <div class="nav">上海链创科技</div>
                </div>
                <div class="box">
                    <div class="title">关于我们</div>
                    <div class="nav">关于小玄猪</div>
                    <div class="nav">联系我们</div>
                    <div class="nav">产品体验</div>
                    <div class="nav">客户服务</div>
                </div>
                <div class="vertical" />
                <div class="code">
                    <div class="title">小玄猪官方公众号</div>
                    <div class="qr">
                        <el-image :src="website.qrcode_gzh" />
                    </div>
                </div>
            </div>

            <div class="link">
                <span>友情链接</span>
                <span>安全中心</span>
                <span>隐私声明</span>
                <el-divider direction="vertical" />
                <span>版权所有</span>
                <span>版权所有&copy;小玄猪</span>
                <span>{{ website.icp }}</span>
            </div>
        </div>

        <!--侧边栏-->
        <SidebarView :website="website" />

        <!--弹窗-->
        <KefuView v-if="showKefu" :show="showKefu" :qrcode="website.qrcode_kefu" @close="closeKefu" />
    </div>
</template>

<script>
import bottom from '../../assets/bottom.png'
import headset from '../../assets/headset.png'
import SidebarView from './SidebarView'
import KefuView from './KefuView'

export default {
    components: { SidebarView, KefuView },
    props: {
        show: {
            type: Boolean,
            require: true
        },
        text: {
            type: String,
            require: true
        },
        button: {
            type: String,
            require: true
        },
        website: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            bottom: bottom,
            headset: headset,
            showKefu: false
        }
    },
    methods: {
        // 跳转
        directUp(path) {
            this.$router.push(path)
        },
        // 打开客服
        openKefu() {
            // 仅限申请加入
            if (this.button === '申请加入') {
                this.showKefu = true
            }
        },
        // 关闭客服
        closeKefu() {
            this.showKefu = false
        }
    }
}
</script>

<style lang="scss" scoped>
.bottom {
    width: 100%;
    height: 172px;

    .desc {
        color: #FFFFFF;
        font-size: 20px;
        padding: 44px 0 24px;
    }

    .button {
        width: 140px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: 16px;
        color: #256BDE;
        font-weight: normal;

        .mg-left7 {
            margin-left: 7px;
        }
    }
}

.bottom-bg {
    width: 100%;
    height: 300px;
    background: #252525;

    .concat {
        display: flex;
        justify-content: center;
        padding: 42px 0 20px;

        .phone {
            padding: 0 60px 0 0;
            text-align: left;

            .consult {
                display: flex;
                align-items: center;

                .el-image {
                    width: 16px;
                    height: 16px;
                }

                .title {
                    color: #FFFFFF;
                    font-size: 14px;
                    line-height: 48px;
                    margin-left: 8px;
                }
            }

            .mobile {
                color: #FFFFFF;
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .text {
                color: #B3B3B3;
                font-size: 12px;
                line-height: 24px;
                max-width: 242px;
            }
        }

        .vertical {
            width: 1px;
            height: 119px;
            background: #535353;
            margin-top: 18px;
        }

        .box {
            padding: 0 60px;
            text-align: left;

            .title {
                color: #D9D9D9;
                font-size: 14px;
                line-height: 48px;
            }

            .nav {
                color: #B3B3B3;
                font-size: 12px;
                line-height: 24px;
                cursor: pointer;
            }
        }

        .code {
            padding: 0 0 0 45px;

            .title {
                color: #FFFFFF;
                font-size: 12px;
                line-height: 48px;
            }

            .qr {
                width: 80px;
                height: 80px;
                background: #FFFFFF;
                margin: 0 auto;

                .el-image {
                    width: 72px;
                    height: 72px;
                    margin: 4px;
                }
            }
        }
    }

    .link {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-top: solid 1px #ECECEC;
        padding-top: 16px;

        span {
            color: #B3B3B3;
            font-size: 12px;
            line-height: 24px;
            padding: 0 10px 0 10px;
        }

        span:first-child {
            padding: 0 10px 0 5px;
        }

        .el-divider--vertical {
            border-left: solid 1px #535353;
            height: 12px;
            margin: 0 10px;
        }
    }
}
</style>
