<template>
    <div class="sidebar">
        <div class="sidebar-show">
            <div v-for="(item, index) in list"
                 :key="index"
                 :style="{ 'background': 'url(' + item.icon + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }"
                 :class="'sidebar-icon relative' + item.id"
                 @click="selectClick(index, item.id)"
                 @mouseover="changeHover(index, true)"
                 @mouseout="changeHover(index, false)"
            />

            <!--弹窗-->
            <div v-if="active_id > 0" class="clue-card">
                <div :style="{ 'background': 'url(' + zbg + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }"
                     class="proper"
                >
                    <!--微信咨询-->
                    <div v-if="active_id === 1" class="proper-content">
                        <div class="proper-title">微信咨询</div>
                        <div class="proper-description">现在咨询，送你新客大礼包</div>
                        <el-image :src="website.qrcode_kefu" fit="contain" />
                        <div class="proper-extra">手机扫码加我微信</div>
                    </div>

                    <!--电话咨询-->
                    <div v-if="active_id === 2" class="proper-content">
                        <div class="proper-title">预约回电</div>
                        <div class="proper-description">专家顾问将在10分钟内向您致电</div>
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="form-phone">
                            <el-form-item prop="phone">
                                <el-input v-model="ruleForm.phone" size="large" maxlength="11" placeholder="填写你的手机号码" />
                            </el-form-item>
                            <el-button type="primary" class="submit-reservation" @click="onSubmit">立即预约</el-button>
                        </el-form>
                        <a :href="'tel:' + website.tel" :underline="false" class="proper-phone">
                            <div class="proper-phone-sub-title">电话咨询（9:00-22:00）</div>
                            <div class="proper-phone-sub-contact">{{ website.tel }}</div>
                        </a>
                    </div>

                    <!--免费试用-->
                    <div v-if="active_id === 3" class="proper-content">
                        <div class="proper-title">预约演示</div>
                        <div class="proper-description">专家顾问会在 3 小时内致电讲解</div>
                        <el-form ref="ruleFormTemp" :model="ruleFormTemp" :rules="rulesTemp" class="form-phone">
                            <el-form-item prop="phone">
                                <el-input v-model="ruleFormTemp.phone" size="large" maxlength="11" placeholder="请输入手机号（必填）" />
                            </el-form-item>
                            <el-form-item prop="place">
                                <el-select v-model="ruleFormTemp.place" size="large" placeholder="所在地区" style="width: 100%">
                                    <el-option v-for="(item, index) in areaList" :key="index" :label="item" :value="item" />
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="intention">
                                <el-select v-model="ruleFormTemp.intention" placeholder="意向产品" style="width: 100%">
                                    <el-option v-for="(item, index) in intentionList" :key="index" :label="item" :value="item" />
                                </el-select>
                            </el-form-item>
                            <el-button type="primary" class="submit-reservation" @click="onSubmitTemp">立即预约</el-button>
                            <el-checkbox v-model="agree" size="large" class="check-font">
                                <span>我已阅读并同意</span>
                                <span class="check-blue">用户协议</span>
                                <span class="check-blue">、</span>
                                <span class="check-blue">隐私政策</span>
                            </el-checkbox>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>

        <!--遮罩层-->
        <div v-if="active_id > 0" class="mask" @click="resetClick" />
    </div>
</template>

<script>
import wechat from '../../assets/sidebar/wechat.png'
import wechat_active from '../../assets/sidebar/wechat_active.png'
import mobile from '../../assets/sidebar/mobile.png'
import mobile_active from '../../assets/sidebar/mobile_active.png'
import demo from '../../assets/sidebar/demo.png'
import demo_active from '../../assets/sidebar/demo_active.png'
import zbg from '../../assets/sidebar/zbg.png'
import { validTelPhone } from '@/utils/validate'
import { reservationCall, reservationDemo } from '@/api/site'

export default {
    props: {
        website: {
            type: Object,
            require: true
        }
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'))
            } else if (!validTelPhone(value)) {
                callback(new Error('请填写正确格式的手机号'))
            } else {
                callback()
            }
        }
        return {
            list: [{
                id: 1,
                icon: wechat
            }, {
                id: 2,
                icon: mobile
            }, {
                id: 3,
                icon: demo
            }],
            zbg: zbg,
            active_id: 0,
            ruleForm: {
                phone: ''
            },
            rules: {
                phone: [{ required: true, trigger: 'blur', validator: validatePhone }]
            },
            ruleFormTemp: {
                phone: '',
                place: '',
                intention: ''
            },
            rulesTemp: {
                phone: [{ required: true, trigger: 'blur', validator: validatePhone }]
            },
            areaList: [
                '北京市',
                '天津市',
                '河北省',
                '山西省',
                '内蒙古',
                '辽宁省',
                '吉林省',
                '黑龙江省',
                '上海市',
                '江苏省',
                '浙江省',
                '安徽省',
                '福建省',
                '江西省',
                '山东省',
                '河南省',
                '湖北省',
                '湖南省',
                '广东省',
                '广西省',
                '海南省',
                '重庆市',
                '四川省',
                '贵州省',
                '云南省',
                '西藏',
                '陕西省',
                '甘肃省',
                '青海省',
                '宁夏',
                '新疆',
                '台湾',
                '香港',
                '澳门'
            ],
            intentionList: [
                '餐饮',
                '电商',
                '服装',
                '互联网',
                '金融'
            ],
            agree: false
        }
    },
    methods: {
        // 点击弹窗
        selectClick(index, id) {
            this.ruleForm.phone = ''
            this.ruleFormTemp.phone = ''
            this.ruleFormTemp.place = ''
            this.ruleFormTemp.intention = ''
            this.active_id = this.active_id !== id ? id : 0
            this.changeIcon()
        },
        // 恢复
        resetClick() {
            this.active_id = 0
            this.changeIcon()
        },
        // 切换图标
        changeIcon() {
            this.list.forEach((item, index) => {
                this.changeHover(index, this.active_id === item.id)
            })
        },
        // 切换图标
        changeHover(index, show) {
            switch (this.list[index].id) {
                case 1:
                    this.list[index].icon = this.active_id === 1 || show ? wechat_active : wechat
                    break
                case 2:
                    this.list[index].icon = this.active_id === 2 || show ? mobile_active : mobile
                    break
                case 3:
                    this.list[index].icon = this.active_id === 3 || show ? demo_active : demo
                    break
            }
        },
        // 预约回电
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (!valid) return false
                reservationCall({ ...this.ruleForm }).then((res) => {
                    if (res.data.code === 1) {
                        this.$message.success(res.data.msg)
                        this.ruleForm.phone = ''
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(err => {
                    console.log(err)
                })
            })
        },
        // 预约演示
        onSubmitTemp() {
            this.$refs.ruleFormTemp.validate((valid) => {
                if (!valid) return false
                if (!this.agree) {
                    this.$message.warning('请勾选同意协议')
                    return false
                }
                reservationDemo({ ...this.ruleFormTemp }).then((res) => {
                    if (res.data.code === 1) {
                        this.$message.success(res.data.msg)
                        this.ruleForm.phone = ''
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(err => {
                    console.log(err)
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar {
    position: fixed;
    right: 22px;
    bottom: 120px;

    .sidebar-show {
        width: 131px;
        height: 250px;
        position: relative;

        .sidebar-icon {
            width: 131px;
            height: 131px;
            cursor: pointer;
            position: absolute;
            z-index: 2;
            left: 0;
        }

        .relative1 {
            top: 0;
        }

        .relative2 {
            top: 75px;
        }

        .relative3 {
            top: 150px;
        }

        .clue-card {
            position: absolute;
            z-index: 2;
            right: 110px;
            bottom: -60px;
            width: 286px;
            height: 385px;
            background-color: #fff;
            background-size: 100% 100%;
            border-radius: 8px;
            box-shadow: 0 6px 20px 0 rgb(50 50 51 / 10%);

            .proper {
                width: 286px;
                height: 385px;

                .proper-content {
                    padding: 36px 20px;

                    .proper-title {
                        font-size: 24px;
                        line-height: 33px;
                        font-weight: 600;
                        margin-bottom: 4px;
                    }

                    .proper-description {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    .el-image {
                        width: 196px;
                        height: 196px;
                        margin-top: 20px;
                    }

                    .proper-extra {
                        line-height: 22px;
                        margin-top: 10px;
                    }

                    .form-phone {
                        margin-top: 24px;

                        .submit-reservation {
                            width: 100%;
                            height: 42px;
                        }

                        .check-font {
                            margin-top: 6px;

                            .check-blue {
                                font-size: 14px;
                                color: #409eff;
                            }
                        }
                    }

                    .proper-phone {
                        display: block;
                        margin-top: 22px;
                        background-repeat: no-repeat;
                        background-size: 34px 34px;
                        background-position: center center;
                        padding-left: 42px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        text-decoration: none;
                        text-align: left;
                        background-image: url('../../assets/sidebar/phone.jpg');

                        .proper-phone-sub-title {
                            line-height: 14px;
                            margin-bottom: 6px;
                        }

                        .proper-phone-sub-contact {
                            line-height: 14px;
                        }
                    }

                    .proper-phone:hover {
                        color: #007aff;
                        background-image: url('../../assets/sidebar/phone-hover.png');
                    }
                }
            }
        }
    }

    .mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}

@media only screen and (min-width: 1440px) {
    .sidebar {
        left: 50%;
        right: auto;
        margin-left: 600px;
    }
}
</style>
