<template>
    <div v-if="!loading" class="home">
        <!--顶部-->
        <div class="top-bg">
            <div class="top-shadow">
                <TopView :website="website" />
            </div>
        </div>

        <!--banner-->
        <BannerTwoView />

        <!--digital-->
        <DigitalView />

        <!--server-->
        <ServerView />

        <!--advantage-->
        <AdvantageView />

        <div class="brand-box">
            <div class="brand-item">
                <div class="text-one">合作伙伴</div>
                <div class="text-two">来自优秀产品的信任</div>
            </div>
            <el-image :src="brand" fit="contain" />
        </div>

        <!--news-->
        <NewsView />

        <!--cert-->
        <CertView />

        <!--底部-->
        <BottomTwoView :show="true" :text="text" :button="button" :website="website" />
    </div>
</template>

<script>
import brand from '../assets/brand.png'
import TopView from './components/TopView'
import BannerTwoView from './components/BannerTwoView'
import DigitalView from './components/DigitalView'
import ServerView from './components/ServerView'
import AdvantageView from './components/AdvantageView'
import NewsView from './components/NewsView'
import CertView from './components/CertView'
import BottomTwoView from './components/BottomTwoView'
import { website } from '@/api/site'

export default {
    components: { TopView, BannerTwoView, DigitalView, ServerView, AdvantageView, NewsView, CertView, BottomTwoView },
    data() {
        return {
            loading: true,
            text: '和小玄猪一起开启数字体验之旅吧！',
            button: '立即体验',
            website: {},
            brand: brand
        }
    },
    created() {
        this.getWebsite()
    },
    methods: {
        // 获取配置
        getWebsite() {
            website({ page_name: '' }).then((res) => {
                this.website = res.data.data.var
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.top-bg {
    width: 100%;
    height: 83px;
    background: #FFFFFF;

    .top-shadow {
        width: 100%;
        height: 60px;
        box-shadow: 0 5px 18px 0 #E2E2E2;
    }
}

.brand-box {
    position: relative;

    .brand-item {
        position: absolute;
        top: 18%;
        left: 0;
        right: 0;
        z-index: 1;

        .text-one {
            font-size: 28px;
            color: #FFFFFF;
            margin-bottom: 15px;
        }

        .text-two {
            font-size: 14px;
            color: #d0cfcf;
        }
    }
}
</style>
