<template>
    <div class="server">
        <div class="title">做好服务 共同成长</div>
        <div class="sub-title">丰富的行业经验  100+应用场景</div>
        <div class="list">
            <div v-for="(item, index) in list" :key="index" class="box">
                <div class="header">
                    <div class="name">{{ item.name }}</div>
                    <el-image :src="item.icon" fit="contain" />
                </div>
                <div class="main">
                    <div v-for="(item1, index1) in item.list" :key="index1" class="info">
                        <el-image :src="item1.icon" fit="contain" />
                        <div class="right">
                            <div class="label">{{ item1.title }}</div>
                            <div class="desc">{{ item1.desc }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-button class="button">进入后台</el-button>
    </div>
</template>

<script>
import channel from '../../assets/icon/channel.png'
import like from '../../assets/icon/like.png'
import industry from '../../assets/icon/industry.png'
import market from '../../assets/icon/market.png'
import icon1 from '../../assets/icon/icon1.png'
import icon2 from '../../assets/icon/icon2.png'
import icon16 from '../../assets/icon/icon16.png'
import icon15 from '../../assets/icon/icon15.png'
import icon12 from '../../assets/icon/icon12.png'
import icon9 from '../../assets/icon/icon9.png'
import icon6 from '../../assets/icon/icon6.png'
import icon3 from '../../assets/icon/icon3.png'
import icon14 from '../../assets/icon/icon14.png'
import icon11 from '../../assets/icon/icon11.png'
import icon8 from '../../assets/icon/icon8.png'
import icon5 from '../../assets/icon/icon5.png'
import icon13 from '../../assets/icon/icon13.png'
import icon10 from '../../assets/icon/icon10.png'
import icon7 from '../../assets/icon/icon7.png'
import icon4 from '../../assets/icon/icon4.png'

export default {
    data() {
        return {
            channel: channel,
            list: [{
                id: 1,
                name: '推广渠道',
                icon: channel,
                list: [{
                    id: 1,
                    icon: icon1,
                    title: '微信商城',
                    desc: '朋友圈/小程序/公众号商城'
                }, {
                    id: 2,
                    icon: icon2,
                    title: '直播平台',
                    desc: '视频号/映客/爱逛边播边卖'
                }, {
                    id: 3,
                    icon: icon16,
                    title: '抖音快手',
                    desc: '视频/直播/引流'
                }, {
                    id: 4,
                    icon: icon15,
                    title: '小红书种草',
                    desc: '社交/分享/种草'
                }]
            }, {
                id: 2,
                name: '新零售',
                icon: like,
                list: [{
                    id: 1,
                    icon: icon12,
                    title: '连锁经营',
                    desc: '多门店/直营/经销/加盟/联营'
                }, {
                    id: 2,
                    icon: icon9,
                    title: '企微助手',
                    desc: '沉淀私域用户、实现精准营销'
                }, {
                    id: 3,
                    icon: icon6,
                    title: '导购助手',
                    desc: '赋能导购员工、业绩爆发增长'
                }, {
                    id: 4,
                    icon: icon3,
                    title: '客户管理',
                    desc: '客户画像分析、提升单客价值'
                }]
            }, {
                id: 3,
                name: '垂直行业',
                icon: industry,
                list: [{
                    id: 1,
                    icon: icon14,
                    title: '社区团购',
                    desc: '低成本开团、引爆熟人社交'
                }, {
                    id: 2,
                    icon: icon11,
                    title: '社区养老',
                    desc: '居家养老数字化服务'
                }, {
                    id: 3,
                    icon: icon8,
                    title: '商超美业',
                    desc: '收银系统、预约、会员卡'
                }, {
                    id: 4,
                    icon: icon5,
                    title: '竞价拍卖',
                    desc: '文玩古董、汽车不动产拍卖'
                }]
            }, {
                id: 4,
                name: '裂变营销',
                icon: market,
                list: [{
                    id: 1,
                    icon: icon13,
                    title: '分销员',
                    desc: '粉丝/客户/员工帮你二级分销'
                }, {
                    id: 2,
                    icon: icon10,
                    title: '好友瓜分券',
                    desc: '裂变瓜分红包、老客带新客'
                }, {
                    id: 3,
                    icon: icon7,
                    title: '会员储值',
                    desc: '会员持续复购，人人都是回头客'
                }, {
                    id: 4,
                    icon: icon4,
                    title: '互动游戏',
                    desc: '客户更活跃‘裂变传播引流'
                }]
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
.server {
    height: 780px;
    padding-top: 100px;
    background: linear-gradient(#F6F9FC, #EAF1FD);

    .title {
        font-size: 28px;
        line-height: 48px;
    }

    .sub-title {
        color: #737373;
        font-size: 16px;
        line-height: 48px;
    }

    .list {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        .box {
            width: 290px;
            height: 420px;
            border: solid 1px #E4E4E4;
            border-radius: 5px;
            background: #FFFFFF;
            margin: 0 10px;

            .header {
                width: 290px;
                height: 68px;
                background: linear-gradient(to right, #367BFC, #5E9BFF);
                border-radius: 5px 5px 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                    color: #FFFFFF;
                    font-size: 18px;
                    margin-left: 20px;
                }

                .el-image {
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                }
            }

            .main {
                padding: 0 20px;

                .info {
                    display: flex;
                    align-items: center;
                    margin-top: 30px;

                    .el-image {
                        width: 42px;
                        height: 42px;
                    }

                    .right {
                        margin-left: 10px;
                        text-align: left;

                        .label {
                            font-size: 16px;
                        }

                        .desc {
                            color: #737373;
                            font-size: 14px;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }

    .button {
        width: 168px;
        height: 48px;
        border: 1px solid #4285F4;
        border-radius: 2px;
        color: #4285F4;
        font-size: 16px;
        font-weight: normal;
        margin-top: 50px;
    }
}
</style>
